<script setup>
import { getCurrentInstance, ref } from 'vue'
import { useRouter } from 'vue-router/composables'
import { useGoogleSignInPopup } from '@/features/access/services/authenticationService'
import { internalApiRequest } from '@/shared/services/apiService'
import googleLogoSrc from '@/app/assets/logos/logo_google.svg'

const app = getCurrentInstance()
const router = useRouter()
const submitted = ref(false)

defineProps({
  label: {
    type: String,
    default: 'Google',
  },
})

function onGoogleSignIn() {
  submitted.value = true

  useGoogleSignInPopup()
    .then((resp) => {
      internalApiRequest({
        method: 'POST',
        path: `api/v1/google-login`,
        data: {
          tokenId: resp?.idToken,
          email: resp?.user?.email,
          firstname: resp?.user?.displayName?.split(' ').slice(0, -1)?.join(' '),
          lastname: resp?.user?.displayName?.split(' ').slice(-1)?.join(' '),
        },
      })
        .then((resp) => {
          localStorage.setItem('token', resp?.access_token)
          router.push({ name: 'dashboard' })
        })
        .catch(() => {
          app.proxy.$notification.error(app.proxy.$t('verification.failed'))
        })
    })
    .finally(() => {
      submitted.value = false
    })
}
</script>

<template>
  <v-btn color="primary" :loading="submitted" block outlined @click="onGoogleSignIn">
    <v-avatar size="25" class="mr-2">
      <v-img :src="googleLogoSrc" alt="" max-height="20px" width="auto" contain></v-img>
    </v-avatar>
    {{ label }}
  </v-btn>
</template>

<style lang="scss" scoped></style>
